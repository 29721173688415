<template>
   <section class="">   
        <v-layout justify-space-around justify-center class="support-layout">
            <v-flex xs9 sm9 offset-sm0 class="">
                    <v-card flat tile class="transparent" color="" height="100%"  column align="center" justify="center"   >
                         <v-card-text class="white--text subheading mb-3 text-xs-center parallax-text support-header">
                            <span class="slider-about-header" :class="{'mobile-header' :is_screen_small}"><span class="slider-subheader zamu-heading ">Zamupay, </span><span class="zamu-normal">Powering Your Growth</span> </span>
                        </v-card-text>
                    </v-card>
            </v-flex>
        </v-layout>
    </section>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Slider',
    mixins: [coreMixin],


}
</script>

<style scoped>
.mobile-header {
    font-size: 23px;
}
</style>